@import 'palette';
@import 'typo';

/*------------------------------------*\
    #Body
\*------------------------------------*/

$body-bg: #f3f3f3;
$body-color: $black;

/*------------------------------------*\
    #Typography
\*------------------------------------*/

$fontFamily: 'Gilroy', sans-serif;

$line-height-base: 1.7;
$font-weight-base: 500;
$font-size-base: calc(1rem + 0.3vw);
$h1-font-size: 5rem;
$h2-font-size: 3.25rem;
$h3-font-size: 2.75rem;
$h4-font-size: 1.875rem;
$h5-font-size: 1.1rem;

$paragraph-margin-bottom: 2rem;

$lead-font-size: calc(1.55rem + 2.8vw);
$lead-font-weight: 600;
$lead-font-line-height: 1.2;
$lead-font-letter-spacing: -1px;

$headings-line-height-base: 1.4;
$headings-line-height: $headings-line-height-base;
$headings-margin-bottom: 1.5rem;
$enable-responsive-font-sizes: true;

/*------------------------------------*\
    #Buttons
\*------------------------------------*/

$global-border-radius: 0.5rem;
$btn-border-radius: 5rem;
$btn-border-radius-sm: $btn-border-radius;

$btn-font-weight: 600;
$btn-font-transform: capitalize;

$btn-padding-y: 0.8rem;
$btn-padding-x: 2rem;
$btn-padding-y-sm: 0.5rem;
$btn-padding-x-sm: 1rem;

$btn-outline-primary-color: $white;
$link-color: map-get($theme-colors, 'primary') !important;

/*------------------------------------*\
    #Container
\*------------------------------------*/

$container-padding-x: 2rem;

/*------------------------------------*\
    #Spacing
\*------------------------------------*/

$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
    6: $spacer * 5,
    7: $spacer * 8,
    8: $spacer * 14,
);

/*------------------------------------*\
    #Loader
\*------------------------------------*/

$loader-bg-color: $black;
$loader-logo-color: $white;

/*------------------------------------*\
    #Navbar & Footer
\*------------------------------------*/

$nav-link-color: white;
$navbar-link-color-on-scroll: black;
$nav-link-font-size: 1rem;
$nav-link-font-weight: 800;
$nav-link-font-transform: uppercase;

$navbar-padding-y: 2.5rem;
$navbar-padding-x: 4rem;
$navbar-collapsed-padding: 1.5rem 4rem;

$navbar-bg-color-on-scroll: $white;
$navbar-secondary-bg-color-on-scroll: $black;

$footer-bg-color: $black;
$footer-font-color: $white;
$footer-bg-copyright: $black;

/*------------------------------------*\
    #Header Images
\*------------------------------------*/

$image-section-height: 100%;
$image-section-md-height: 40rem;
$image-section-sm-height: 30rem;
$image-section-overlay: rgba(0, 0, 0, 0.65);

/*------------------------------------*\
    #Cards
\*------------------------------------*/

$card-border: 1px solid $gray-600;
$card-border-radius: 0.5rem;

$card-bg-color: $white;
$card-border-color: $white;

$card-dark-bg-color: rgb(0 0 0 / 40%);
$card-dark-border: 1px solid $white;

$card-avatar-color: $gray-600;

$card-padding: 2rem;

/*------------------------------------*\
    #Modal
\*------------------------------------*/

$modal-backdrop-bg: #000;
$modal-backdrop-opacity: 0.8;
$modal-fade-transform: scale(1);
$modal-content-bg: #161616;
$modal-inner-padding: 2rem;

/*------------------------------------*\
    #Accordion
\*------------------------------------*/

$accordion-bg: transparent;
$accordion-button-active-bg: transparent;
$accordion-border-radius: 0;
$accordion-border-width: 0;
$accordion-button-focus-box-shadow: unset;
$accordion-button-padding-y: 1.2rem;
$accordion-button-padding-x: 0;
