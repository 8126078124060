/*
 * Themes for each client.
 */

@import 'theme-variables';
@import 'global-variables';

// Logo Loader

.logo-loader {
    background: radial-gradient(farthest-side at top, #000 0, #0c0c0c 100%);
    width: 12rem;
    height: 12rem;
    border-radius: 1rem;
}

// Container

.container-fluid {
    width: 90% !important;
    @media (max-width: $tablet) {
        width: 100% !important;
    }
}

// Navbar

.o-navbar {
    &.navbar-secondary {
        .active {
            color: $navbar-link-color-on-scroll !important;
        }
        background-color: transparent;
        transition: all 0.3s ease-in;
        .nav-link {
            color: $navbar-link-color-on-scroll;
        }
        .navbar-nav {
            .show > .nav-link {
                color: $navbar-link-color-on-scroll;
            }
        }
        .btn {
            color: $navbar-link-color-on-scroll;
        }
        svg {
            .logotype {
                path {
                    fill: $navbar-link-color-on-scroll;
                }
            }
        }
        .menu-container {
            @media (max-width: $tablet) {
                i {
                    color: $navbar-link-color-on-scroll !important;
                }
            }
        }
        &.navbar-on-scroll {
            background-color: $navbar-secondary-bg-color-on-scroll;
            .nav-link {
                color: $nav-link-color;
            }
            .active {
                color: $nav-link-color !important;
            }
            .navbar-nav {
                .show > .nav-link {
                    color: $nav-link-color;
                }
            }
            .btn {
                color: white;
            }
            svg {
                .logotype {
                    path {
                        fill: $nav-link-color;
                    }
                }
            }
            .menu-container {
                @media (max-width: $tablet) {
                    i {
                        color: $nav-link-color !important;
                    }
                }
            }
        }
    }
    &.navbar-on-scroll {
        svg {
            .logotype {
                path {
                    @media (min-width: $tablet) {
                        fill: $navbar-link-color-on-scroll;
                    }
                }
            }
        }
    }
}

// Mobile Menu
.o-navbar {
    .modal-header {
        border: 0;
        @media (max-width: $tablet) {
            .icon-close {
                svg {
                    rect {
                        fill: white;
                    }
                }
            }
            .scalio-logo-mobile {
                svg {
                    width: 3.4rem;
                    height: auto;
                }
            }
        }
    }
    .modal-body {
        @media (max-width: $tablet) {
            padding: 2rem 1rem;
        }
    }

    .navbar-nav {
        .dropdown-menu {
            @media (max-width: $tablet) {
                display: block;
                background: transparent;
                border: 0;
                button {
                    color: white;
                }
            }
        }
    }
}

.modal-backdrop {
    z-index: 1050 !important;
}

// Footer

.o-footer {
    button {
        padding: 0;
    }
    p {
        text-align: left;
    }
    .main-links {
        .link {
            font-size: 1.3rem;
            font-weight: 700;
            text-transform: uppercase;
            color: $gray-400;
            transition: all 0.3s ease-in-out;
            &:hover {
                opacity: 0.5;
                transition: all 0.3s ease-in-out;
            }
        }
        img {
            margin-top: 6rem;
            @media (max-width: $mobile) {
                margin-top: 2rem;
            }
        }
        @media (max-width: $mobile) {
            margin-bottom: 3rem;
        }
    }
    .offices-footer {
        @media (max-width: $mobile) {
            margin-bottom: 3rem;
        }
    }
    .category-footer {
        text-transform: uppercase;
        color: $gray-400 !important;
        margin-bottom: 0.5rem;
    }
    .company-footer {
        .link {
            color: $gray-400 !important;
            transition: all 0.3s ease-in-out;
            &:hover {
                opacity: 0.5;
                transition: all 0.3s ease-in-out;
            }
        }
    }
    .copyright {
        border-top: 1px solid #222;
        padding-top: 2rem;
        svg {
            width: 2.3rem;
            height: auto;
            fill: $gray-400;
            transition: all 0.3s ease-in-out;
            path {
                fill: $gray-400;
            }
            &:hover {
                opacity: 0.5;
                transition: all 0.3s ease-in-out;
            }
        }
        .email-contrast {
            color: lighten(#d5333e, 10%);
        }
        .container-fluid {
            padding-bottom: 4rem;
        }
        .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media (max-width: $mobile) {
                flex-direction: column;
            }
            .social-logos {
                display: flex;
                flex-flow: wrap;
                gap: 1rem;
                @media (max-width: $mobile) {
                    justify-content: center;
                }
                :nth-child(2) {
                    svg {
                        width: 2.7rem;
                        height: auto;
                    }
                }
            }
            .right-content {
                display: flex;
                align-items: center;
                @media (max-width: $tablet) {
                    margin-top: 2rem;
                    flex-direction: column;
                }
                p {
                    font-weight: 400;
                }
            }
        }
    }
}

//Buttons

.btn {
    &:hover {
        transform: scale(1.1);
        transition: all 0.3s ease;
        background-color: map-get($theme-colors, 'primary') !important;
        color: $white !important;
    }
}

.btn-outline-primary {
    border-color: map-get($theme-colors, 'primary') !important;
}

//Video

.video-js {
    .vjs-tech {
        width: auto !important;
        @media (max-width: $mobile) {
            left: -30rem !important;
        }
    }
}

// Header Video

.arrow-down {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 15rem;
    display: flex;
    justify-content: center;
    fill: white;
}

.header-logo-container {
    .trusted-logos {
        width: 80%;
        @media (max-width: $tablet) {
            width: 100%;
            margin: 1rem;
        }
    }
}

// Section Title

.half-title {
    color: map-get($theme-colors, 'primary');
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 0;
}

//Careers Page
.o-job-application-two-cols {
    .form-field-textarea {
        height: 11rem !important;
    }
}

// Home Page

.home-page {
    #nextSection {
        h2 {
            width: 21ch !important;
        }
    }
    .inspiring-innovation {
        h2 {
            width: 17ch;
        }
    }
    .o-bg-image-section {
        min-height: 700px !important;
    }
    .innovative-products {
        h2 {
            width: 23ch !important;
        }
        p {
            @media (min-width: $tablet) {
                width: 61ch !important;
            }
        }
        .a-icon {
            i {
                font-size: 3rem !important;
            }
        }
        .m-card-item {
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;
            .body {
                h4 {
                    font-size: calc(1rem + 0.3vw);
                    font-weight: 600;
                }
                svg,
                i {
                    transition: all 0.5s ease;
                }
                text-align: center !important;
                rect,
                path {
                    stroke: map-get($theme-colors, 'primary') !important;
                }
            }
            &:hover {
                transform: translateY(-8px);
                svg,
                i {
                    transform: scale3d(1.2, 1.2, 1);
                    transition: all 0.5s ease;
                }
            }
        }
    }
    .tested-quality {
        .m-card-item {
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;
            p {
                color: white;
            }
            .body {
                svg {
                    transition: all 0.5s ease;
                }
                rect,
                path {
                    stroke: white !important;
                }
                blockquote {
                    font-size: calc(1rem + 0.3vw);
                    font-weight: 400;
                }
                blockquote ::before {
                    opacity: 0.3;
                    margin-right: unset !important;
                    padding-left: 1.5rem;
                }
                text-align: center !important;
            }
            &:hover {
                transform: translateY(-8px);
                svg {
                    transform: scale3d(1.2, 1.2, 1);
                    transition: all 0.5s ease;
                    height: 2.5rem;
                }
            }
            svg {
                height: 2.5rem;
                width: 10rem;
            }
        }
        .m-section-title {
            justify-content: center;
            width: 100%;
            h2 {
                font-size: calc(1.5rem + 0.5vw) !important;
                font-weight: 400 !important;
            }
        }
        .carousel-control-prev,
        .carousel-control-next {
            @media (min-width: $desktop) {
                width: 3%;
            }
        }
    }
    .open-sourced {
        h2 {
            width: 15ch !important;
        }
        p {
            @media (min-width: $desktop) {
                width: 36ch !important;
            }
        }
    }
    .who-we-are {
        h2 {
            width: 21ch !important;
        }
        p {
            @media (min-width: $desktop) {
                width: 56ch !important;
            }
        }
    }
    .what-we-create {
        h2 {
            width: 17ch !important;
        }
        p {
            @media (min-width: $desktop) {
                width: 42ch !important;
            }
        }
    }
    .dec {
        color: #757575;
    }
}

// Work page
.our-work-page {
    .hero-section {
        h1 {
            width: 23ch !important;
        }
        p {
            @media (min-width: $tablet) {
                width: 68ch !important;
            }
        }
    }
    ::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
    }
    .container-fluid {
        width: 100% !important;
    }
    .our-work-page-tab-filter {
        .nav-tabs {
            display: block;
        }
        .m-tabs {
            .nav-link {
                padding: 0 0 1rem !important;
                cursor: pointer;
                font-weight: 500;
            }
            .tab-content {
                margin-top: 0rem !important;
            }
        }
    }

    hr {
        border-color: map-get($theme-colors, 'primary') !important;
    }
    .m-image-card-item {
        i {
            font-size: 2rem !important;
            color: white !important;
        }
    }
    .accordion-body {
        padding: 0;
        max-height: 16rem;
        overflow: auto;
        padding-bottom: 1rem;
        ul {
            max-height: 15vh;
            overflow-y: scroll;
        }
        ::-webkit-scrollbar {
            width: 0px !important;
            background: transparent !important; /* make scrollbar transparent */
        }
    }
    .accordion-button {
        font-weight: 500;
        border-top: 1px solid $gray-400;
        border-radius: 0 !important;
    }
    .accordion-button:hover {
        color: map-get($theme-colors, 'primary') !important;
    }
    .accordion-button::after {
        background-image: url('../assets/icons/filter-plus.png');
    }
    .accordion-button:not(.collapsed)::after {
        background-image: url('../assets/icons/filter-minus.png');
        transform: rotate(-180deg);
    }
    .o-filter-navigation {
        button {
            border: 0;
            background: transparent;
        }
        @media (max-width: $mobile) {
            display: none;
        }
    }
}

.our-work-page-trusted {
    .logo {
        .a-image {
            text-align: center;
            .img-fluid {
                width: unset !important;
            }
        }
    }
}

// Work Detail page

.our-work-detail-page {
    img {
        max-width: 100%;
    }
    .o-bg-image-section {
        min-height: 600px !important;
        align-items: flex-end !important;
        background-size: cover !important;
        .container {
            padding: 4rem 0 !important;
        }
    }
    .logo {
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
        opacity: 0.8;
        margin: auto;
    }
}

.o-filter-navigation {
    .link {
        color: #000;
        font-size: 1.2rem;
        text-align: left;
        width: 100%;
    }

    .link-active {
        color: map-get($theme-colors, 'primary') !important;
    }
}

.sticky-top {
    top: 8rem !important;
}

// Blog Page

.blog-page {
    hr {
        border-color: map-get($theme-colors, 'primary') !important;
    }
    .blog-divider {
        border-color: $gray-500 !important;
        margin: 8rem 0;
    }
    .m-table-item {
        > div:last-child {
            padding: 2rem;
        }
        h2 {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        p {
            color: $gray-600;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        .medium-size {
            font-weight: 600;
            color: $gray-700;
            text-transform: capitalize;
        }
    }
}

// Blog Detail Page

.blog-detail-page {
    .m-social-networks {
        margin-left: 0.5rem;
        svg {
            width: 2.5rem;
            height: 2.5rem;
        }
        .twitter {
            svg {
                circle {
                    fill: #000 !important;
                }
            }
        }
        .linkedin {
            svg {
                path {
                    fill: #000 !important;
                }
            }
        }
    }
}

// Job Page
.job-page {
    .hero-section {
        h1 {
            @media (min-width: $tablet) {
                width: 23ch !important;
            }
        }
        p {
            @media (min-width: $tablet) {
                width: 50ch !important;
            }
        }
    }
    .o-bg-infinite-image-section {
        height: 1000px !important;
    }
    .o-bg-image-section {
        background-size: cover !important;
        .content {
            height: 100% !important;
        }
    }
    .accordion {
        scroll-margin: 200px;
    }
    .accordion-button {
        &:not(.collapsed) {
            color: white;
        }
        color: #d5333e;
        padding: 1rem;
        @media (max-width: 1199px) {
            padding: 0;
        }
        font-weight: 600;
        font-size: $h4-font-size;
        -webkit-mask-image: linear-gradient(-75deg, rgba(0, 0, 0, 0.6) 50%, #000 70%, rgba(0, 0, 0, 0.6) 90%);
        -webkit-mask-size: 200%;
        animation: shine 2s linear infinite;
        margin-bottom: 1rem;
        &:hover {
            background: linear-gradient(120deg, #000 8%, #181818 49%, #000 74%);
        }
    }
    .accordion-body {
        background: linear-gradient(120deg, #000 8%, #181818 49%, #000 74%);
        margin-bottom: 3rem;
    }
    .location-content {
        right: 3rem;
        top: 1.5rem;
        z-index: 19;
        @media (max-width: 1199px) {
            position: relative !important;
            right: 0;
            top: 0;
        }
    }
    hr {
        margin: 2rem 0;
    }
    .accordion-button::after {
        background-image: url('../assets/icons/filter-red-plus.png');
    }
    .accordion-button:not(.collapsed)::after {
        background-image: url('../assets/icons/filter-red-minus.png');
        transform: rotate(-180deg);
    }
    .m-box-image-item {
        min-height: 20rem !important;
        text-align: center;
        h4 {
            font-weight: 600;
            font-size: 2rem !important;
        }
        p {
            font-size: 1rem !important;
            color: $gray-400 !important;
        }
    }
    .glassdoor-section {
        .glassdoor-title {
            width: 100%;
            @media (min-width: $tablet) {
                width: 30rem;
            }
            height: auto;
            margin: 0 auto;
        }
        a {
            color: white;
        }
        .carousel-caption {
            position: relative;
            right: 0;
            left: 0;
            text-align: left;
        }
    }
}

blockquote ::before {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: '';
    background-image: url('/assets/img/white-quote.png');
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out;
    float: left;
    margin-right: 10px;
}

@keyframes shine {
    from {
        -webkit-mask-position: 150%;
    }
    to {
        -webkit-mask-position: -50%;
    }
}

// Events Page

.events-page {
    .hero-section {
        h1 {
            @media (min-width: $tablet) {
                width: 20ch !important;
            }
        }
        p {
            @media (min-width: $tablet) {
                width: 56ch !important;
            }
        }
    }
}

// Events Detail Page

.events-detail-page {
    .m-image-section-item{
        &.bgImage{
            align-items: flex-end !important;
        }
    }
    .m-social-networks {
        margin-left: 0.5rem;
        svg {
            width: 2.5rem;
            height: 2.5rem;
        }
        .twitter {
            svg {
                circle {
                    fill: #000 !important;
                }
            }
        }
        .linkedin {
            svg {
                path {
                    fill: #000 !important;
                }
            }
        }
    }
}

// Contact Page

.contact-page {
    h3 {
        font-size: 1.8rem;
        font-weight: 400;
    }
    .contact-form {
        background: linear-gradient(to right, rgba(22, 22, 22, 0) 0, #1f1f1f 100%);
    }
    .content {
        > div {
            @media (min-width: $desktop) {
                padding: 7rem;
            }
            padding: 2rem;
        }
    }
    .next-steps {
        max-width: 41rem;
        ol {
            margin-bottom: 0;
            padding-top: 10px;
            padding-left: 0;
            list-style: none;
            counter-reset: item;
            li {
                opacity: 0.8;
                font-weight: 400;
                line-height: 1.88;
                position: relative;
                margin-bottom: 24px;
                padding-left: 48px;
                font-size: 1rem;
                &:before {
                    content: counter(item) '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    background: #161616;
                    color: #fff;
                    font-size: 0.875rem;
                    font-weight: 500;
                    line-height: 32px;
                    text-align: center;
                    counter-increment: item;
                }
            }
        }
    }
    .o-post-form {
        .form-control {
            padding: 1.8rem 0.5rem !important;
            font-size: 1.3rem !important;
            @media (min-width: $desktop) {
                max-width: 23rem;
                min-width: 18rem;
            }
        }
        .btn {
            margin: 0 auto;
            display: flex;
        }
    }

    .m-box-image-item {
        min-height: 20rem !important;
        text-align: center;
        h4 {
            font-weight: 600;
            font-size: 2rem !important;
        }
        p {
            font-size: 1rem !important;
            color: $gray-400 !important;
        }
    }
    .m-cta-banner {
        .background {
            background: linear-gradient(to right, #292929, #000) !important;
        }
    }
}

// Team Page

.team-page {
    .hero-section {
        h1 {
            @media (min-width: $tablet) {
                width: 23ch !important;
            }
        }
        p {
            @media (min-width: $tablet) {
                width: 68ch !important;
            }
        }
    }
    .a-image {
        width: 1.8rem;
        height: auto;
        opacity: 0.5;
        margin-top: 1rem;
    }
    .m-box-image {
        h4 {
            color: white;
            font-size: 1.125rem;
        }
        p {
            color: $gray-500;
            font-size: 1rem;
        }
        .img-rounded {
            filter: grayscale(1);
        }
    }
    .m-box-image {
        .overlay {
            display: none !important;
        }
    }
    .m-social-networks {
        .linkedin {
            svg {
                path {
                    transition: all 0.3s ease-in-out;
                }
            }
            &:hover {
                svg {
                    path {
                        fill: #0072b1 !important;
                        transition: all 0.3s ease-in-out;
                    }
                }
            }
        }
        .github {
            svg {
                path {
                    transition: all 0.3s ease-in-out;
                }
            }
            &:hover {
                svg {
                    path {
                        fill: #fff !important;
                        transition: all 0.3s ease-in-out;
                    }
                }
            }
        }
        .twitter {
            svg {
                circle {
                    transition: all 0.3s ease-in-out;
                }
                path {
                    fill: #000 !important;
                    transition: all 0.3s ease-in-out;
                }
            }
            &:hover {
                svg {
                    circle {
                        fill: #0072b1 !important;
                        transition: all 0.3s ease-in-out;
                    }
                }
            }
        }
    }
    .m-cta-banner {
        .background {
            background: linear-gradient(to right, #292929, #000) !important;
        }
    }
}

// Modal Teams Page Members

.modal-team-page-content {
    padding: 3rem;
    @media (max-width: $mobile) {
        padding: 1rem;
    }
    .image {
        @media (min-width: $tablet) {
            margin-right: 2rem;
        }
    }
    h2,
    p {
        @media (max-width: $mobile) {
            text-align: center;
        }
    }
    p {
        font-size: 1rem;
    }
    ul {
        margin-bottom: 0;
        padding-top: 10px;
        padding-left: 0;
        list-style: none;
        counter-reset: item;
        white-space: normal;
        li {
            opacity: 0.8;
            font-weight: 300;
            line-height: 1.88;
            position: relative;
            margin-bottom: 15px;
            padding-left: 48px;
            &:before {
                content: counter(item) '';
                position: absolute;
                top: 0;
                left: 0;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background: #333;
                color: #fff;
                font-size: 14px;
                font-weight: 500;
                line-height: 32px;
                text-align: center;
                counter-increment: item;
                margin-top: 3px;
            }
        }
    }
    .m-social-networks {
        @media (max-width: $mobile) {
            justify-content: center;
        }
        .linkedin {
            svg {
                path {
                    transition: all 0.3s ease-in-out;
                }
            }
            &:hover {
                svg {
                    path {
                        fill: #0072b1 !important;
                        transition: all 0.3s ease-in-out;
                    }
                }
            }
        }
        .github {
            svg {
                path {
                    transition: all 0.3s ease-in-out;
                }
            }
            &:hover {
                svg {
                    path {
                        fill: #fff !important;
                        transition: all 0.3s ease-in-out;
                    }
                }
            }
        }
        .twitter {
            svg {
                circle {
                    transition: all 0.3s ease-in-out;
                }
                path {
                    fill: #000 !important;
                    transition: all 0.3s ease-in-out;
                }
            }
            &:hover {
                svg {
                    circle {
                        fill: #0072b1 !important;
                        transition: all 0.3s ease-in-out;
                    }
                }
            }
        }
    }
}

// Testimonial Page

.testimonials-page {
    .m-testimonial-card-item {
        p {
            font-size: 1.1rem;
            line-height: 1.7;
        }
        h6 {
            margin-left: 1rem !important;
            font-weight: 400 !important;
            color: map-get($theme-colors, 'primary') !important;
            &:before {
                content: '- ';
            }
        }
        .work-item-img {
            border: 1px solid #eee;
            box-shadow: 0 3px 6px rgb(0 0 0 / 2%), 0 3px 6px rgb(0 0 0 / 3%);
            border-radius: 3px;
            transition: all 0.3s ease-in-out;
            &:hover {
                box-shadow: 0 14px 28px rgb(0 0 0 / 7%), 0 10px 10px rgb(0 0 0 / 7%);
                opacity: 0.3;
                transition: all 0.3s ease-in-out;
                cursor: pointer;
            }
        }
    }
}

// Industries Page

.industries-page {
    .m-image-card-item {
        .content {
            opacity: 1 !important;
        }
    }
}

// Initiatives

.initiatives-page {
    .hero-section {
        h1 {
            width: 14ch !important;
        }
        p {
            @media (min-width: $tablet) {
                width: 36ch !important;
            }
        }
    }
    .m-cta-banner {
        .background {
            background: linear-gradient(to right, #292929, #000) !important;
        }
        .medium-size {
            font-size: 1rem !important;
        }
    }
    .m-image-card-item {
        .content {
            opacity: 1 !important;
        }
    }
}

// Investements Page

.investments-page {
    .m-image-card-item {
        .content {
            opacity: 1 !important;
        }
    }
}

// Open Source Page

.open-source-page {
    .m-image-card-item {
        .content {
            opacity: 1 !important;
        }
    }
    .a-image {
        img {
            width: 6.25rem !important;
        }
    }
}

// Human Rights Page

.human-rights-page {
    .m-image-card-item {
        .content {
            opacity: 1 !important;
        }
    }
    .a-image {
        text-align: center;
        img {
            width: auto !important;
        }
    }
}

// Services Page

.services-page {
    .hero-section {
        h1 {
            width: 18ch !important;
        }
        p {
            @media (min-width: $tablet) {
                width: 68ch !important;
            }
        }
    }
    .accordion-body {
        padding: 0;
        overflow: auto;
        padding-bottom: 1rem;
        ol {
            margin-bottom: 0;
            padding-top: 10px;
            padding-left: 0;
            list-style: none;
            counter-reset: item;
            white-space: normal;
        }
        li {
            position: relative;
            opacity: 0.8;
            font-weight: 300;
            line-height: 1.88;
            position: relative;
            margin-bottom: 15px;
            padding-left: 48px;
            &:before {
                content: counter(item) '';
                position: absolute;
                top: 0;
                left: 0;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background: #f7f7f7;
                color: #818181;
                font-size: 14px;
                font-weight: 500;
                line-height: 32px;
                text-align: center;
                counter-increment: item;
            }
        }
    }
    .accordion-item {
        border-bottom: 1px solid $gray-400;
        border-radius: 0 !important;
    }
    .accordion-button:hover {
        color: map-get($theme-colors, 'primary') !important;
    }
    .accordion-button::after {
        background-image: url('../assets/icons/filter-plus.png');
    }
    .accordion-button:not(.collapsed)::after {
        background-image: url('../assets/icons/filter-minus.png');
        transform: rotate(-180deg);
    }
}

// About Page

.about-page {
    .hero-section {
        h1 {
            width: 18ch !important;
        }
        p {
            @media (min-width: $tablet) {
                width: 68ch !important;
            }
        }
    }
    .m-box-image-item {
        min-height: 20rem !important;
        text-align: center;
        h4 {
            font-weight: 600;
            font-size: 2rem !important;
        }
        p {
            font-size: 1rem !important;
            color: $gray-400 !important;
        }
    }
    .clients-section {
        svg {
            width: 100%;
            height: auto;
            path {
                fill: $gray-400;
            }
        }
    }
    .company-stats-section {
        svg {
            width: 2rem;
            height: auto;
            margin-bottom: 1rem;
            path {
                fill: $gray-400;
            }
        }
    }
    .bg-dark {
        background-color: #161616;
    }
    .lines-anim {
        margin-left: -20px;
    }
    .line1,
    .line2,
    .line3,
    .line4 {
        stroke-dasharray: 2300;
        stroke-dashoffset: 2300;
        animation: dash 7s linear forwards;
    }

    @keyframes dash {
        to {
            stroke-dashoffset: 1;
        }
    }

    .image-animation-container {
        img {
            width: 100%;
            animation: move 40s ease infinite;
        }
    }

    // SLOW SCALE-IN (for photos)
    @keyframes move {
        0% {
            -webkit-transform-origin: bottom left;
            -moz-transform-origin: bottom left;
            -ms-transform-origin: bottom left;
            -o-transform-origin: bottom left;
            transform-origin: bottom left;
            transform: scale(1);
            -ms-transform: scale(1);
            /* IE 9 */

            -webkit-transform: scale(1);
            /* Safari and Chrome */

            -o-transform: scale(1);
            /* Opera */

            -moz-transform: scale(1);
            /* Firefox */
        }

        100% {
            transform: scale(1.2);
            -ms-transform: scale(1.2);
            /* IE 9 */

            -webkit-transform: scale(1.2);
            /* Safari and Chrome */

            -o-transform: scale(1.2);
            /* Opera */

            -moz-transform: scale(1.2);
            /* Firefox */
        }
    }
}
