@import 'breakpoints';

/*------------------------------------*\
    Typography
\*------------------------------------*/

@font-face {
    font-family: 'AvenirNextLTPro';
    font-style: regular;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Regular.ttf') format('truetype'),
        url('/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Regular.woff2') format('woff2'),
        url('/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Regular.woff') format('woff');
}

@font-face {
    font-family: 'AvenirNextLTPro';
    font-style: bold;
    font-weight: 600;
    font-display: swap;
    src: url('/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Medium.ttf') format('truetype'),
        url('/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Medium.woff2') format('woff2'),
        url('/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Medium.woff') format('woff');
}

@font-face {
    font-family: 'AvenirNextLTPro';
    font-style: bold;
    font-weight: 800;
    font-display: swap;
    src: url('/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Bold.ttf') format('truetype'),
        url('/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Bold.woff2') format('woff2'),
        url('/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Poppins';
    font-style: thin;
    font-weight: 300;
    font-display: swap;
    src: url('/assets/fonts/Poppins/Poppins-Thin.ttf') format('truetype'),
        url('/assets/fonts/Poppins/Poppins-Thin.woff2') format('woff2'),
        url('/assets/fonts/Poppins/Poppins-Thin.woff') format('woff');
}

@font-face {
    font-family: 'Poppins';
    font-style: regular;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/Poppins/Poppins-Light.ttf') format('truetype'),
        url('/assets/fonts/Poppins/Poppins-Light.woff2') format('woff2'),
        url('/assets/fonts/Poppins/Poppins-Light.woff') format('woff');
}

@font-face {
    font-family: 'Poppins';
    font-style: medium;
    font-weight: 500;
    font-display: swap;
    src: url('/assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype'),
        url('/assets/fonts/Poppins/Poppins-Medium.woff2') format('woff2'),
        url('/assets/fonts/Poppins/Poppins-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Poppins';
    font-style: semi-bold;
    font-weight: 700;
    font-display: swap;
    src: url('/assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype'),
        url('/assets/fonts/Poppins/Poppins-SemiBold.woff2') format('woff2'),
        url('/assets/fonts/Poppins/Poppins-SemiBold.woff') format('woff');
}

@font-face {
    font-family: 'Gilroy';
    font-style: thin;
    font-weight: 300;
    font-display: swap;
    src: url('/assets/fonts/Gilroy/gilroy-light.ttf') format('truetype'),
        url('/assets/fonts/Gilroy/gilroy-light.woff2') format('woff2'),
        url('/assets/fonts/Gilroy/gilroy-light.woff') format('woff');
}

@font-face {
    font-family: 'Gilroy';
    font-style: regular;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/Gilroy/gilroy-regular.ttf') format('truetype'),
        url('/assets/fonts/Gilroy/gilroy-regular.woff2') format('woff2'),
        url('/assets/fonts/Gilroy/gilroy-regular.woff') format('woff');
}

@font-face {
    font-family: 'Gilroy';
    font-style: medium;
    font-weight: 500;
    font-display: swap;
    src: url('/assets/fonts/Gilroy/gilroy-medium.ttf') format('truetype'),
        url('/assets/fonts/Gilroy/gilroy-medium.woff2') format('woff2'),
        url('/assets/fonts/Gilroy/gilroy-medium.woff') format('woff');
}

@font-face {
    font-family: 'Gilroy';
    font-style: semi-bold;
    font-weight: 700;
    font-display: swap;
    src: url('/assets/fonts/Gilroy/gilroy-semi-bold.ttf') format('truetype'),
        url('/assets/fonts/Gilroy/gilroy-semi-bold.woff2') format('woff2'),
        url('/assets/fonts/Gilroy/gilroy-semi-bold.woff') format('woff');
}

// Styles

.regular-weight {
    font-weight: 400;
}
.bold-weight {
    font-weight: 800;
}

.fw-bold {
    font-weight: 500 !important;
}
.fw-bolder {
    font-weight: 700 !important;
}
